$(document).ready(function () {
    /**
     * Меню
     */
    $(".header-menu__open").click(function () {
        var $this = $(this);

        if($this.hasClass("header-menu__open_opened")) {
            $this.removeClass("header-menu__open_opened");
            $(".header-menu").fadeOut();

            $(".header").removeClass("header_theme_white");
        } else {
            $this.addClass("header-menu__open_opened");
            $(".header-menu").fadeIn();

            $(".header").addClass("header_theme_white");
        }
    });

    $(document).mouseup(function (e) {
        if($(document).innerWidth()) {
            var container = $(".header");
            if (!container.is(e.target) && container.has(e.target).length === 0 && !$(".header-menu__open_opened").is(e.target)) {
                $(".header-menu__open").removeClass("header-menu__open_opened");
                $(".header-menu").fadeOut();

                $(".header").removeClass("header_theme_white");
            }
        }
    });

    /**
     * Номера
     */
    $(".apartments-item-slider").slick({
        dots: false,
        arrows: false,
        asNavFor: ".apartments-item-nav",
        infinite: false
    });
    $(".apartments-item-nav").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        asNavFor: ".apartments-item-slider",
        infinite: false
    });

    $(".apartments-tabs__tab").click(function () {
        var $this = $(this),
            id    = $this.data("id");

        $(".apartments-tabs__tab").removeClass("apartments-tabs__tab_active");
        $this.addClass("apartments-tabs__tab_active");

        $(".apartments-item").removeClass("apartments-item_active").hide();
        $('.apartments-item[data-id="' + id + '"]').addClass("apartments-item_active").fadeIn();

        $(".apartments-item-slider").slick('setPosition');
        $(".apartments-item-nav").slick('setPosition');

        $(".apartments-item-slider").slick("slickGoTo", 0);


        var to = $(".apartments-items");
        var top = $(to).offset().top + 1;

        top = top - $(".header").innerHeight() - 30;

        if($(document).innerWidth() < 1920) {
            $(".header-menu").fadeOut();
            $(".header-menu__open").removeClass("header-menu__open_opened");
        }

        $(".header").removeClass("header_theme_white");

        $("body, html").animate({scrollTop: top}, 500);
    });

    $(".apartments-item-slider").slick('setPosition');
    $(".apartments-item-nav").slick('setPosition');

    $(".apartments-item__select-prices").change(function () {
        var $this = $(this),
            value = $this.val(),
            $apartment = $this.closest(".apartments-item");

        $apartment.find(".apartments-item__price-value").text(value);

        console.log(value);

        // Your code...
    });

    /**
     * Popup
     */
    $("body").on("click", "[data-popup]", function (e) {
        e.preventDefault();

        var el = $(this).data("popup");
        var $this = $(this);

        $.fancybox.open($(".popup-" + el), {
            touch: false,
            helpers: {
                thumbs: {
                    width: 50,
                    height: 50
                },
                overlay: {
                    locked: true
                }
            },
            beforeShow: function () {
            },
            afterShow: function () {
            }
        });
    });

    /**
     * Плавный scroll
     */
    $(".scroll-to").click(function () {
        var to = $(this).data("to") || $(this).attr("href");

        if ($(to).length > 0) {
            $.fancybox.close();

            if (event) event.preventDefault();

            var top = $(to).offset().top + 1;

            top = top - $(".header").innerHeight() - 30;

            if (to == "#promo") {
                top = 0;
            }

            if($(document).innerWidth() < 1920) {
                $(".header-menu").fadeOut();
                $(".header-menu__open").removeClass("header-menu__open_opened");
            }

            $(".header").removeClass("header_theme_white");

            $("body, html").animate({scrollTop: top}, 500);
        }
    });

    /**
     * Формы
     */
    $("form").submit(function () {
        return false;
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj, pObj, cpName;
            $.each(pair.name.split("."), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        if($(form).hasClass("no-valid")) {
            return false;
        }

        var formData = getFormObject($(form).serializeArray());

        axios({
            method: "post",
            url: "./sender.php",
            data: formData,
            config: {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
        }).then(function (response) {
            // Your code
        }).catch(function (response) {});
    }

    $("form").each(function () {
        $(this).validate({
            rules: {
                name: {
                    required: true,
                },
                method: {
                    required: true,
                },
                message: {
                    required: true
                }
            },
            submitHandler: function (form) {
                submitForm(form);
            }
        });
    });

    /**
     * Меню
     */
    var menu_selector = ".menu-list";
    function onScroll() {
        var scroll_top = $(document).scrollTop();

        $(menu_selector + " a").each(function () {
            var hash = $(this).attr("href");

            if(hash !== "#" && hash.length > 2 && hash[0] !== "/") {
                var target = $(hash);
                if (target.length) {
                    if (target.position().top - $(".header").innerHeight() <= scroll_top && target.position().top + target.outerHeight() > scroll_top) {
                        $(menu_selector + " .menu-list__link").removeClass("menu-list__link_active");
                        $(this).addClass("menu-list__link_active");
                    } else {
                        $(this).removeClass("menu-list__link_active");
                    }
                }
            }
        });

        var $header = $(".header");

        if (scroll_top > $(".promo").innerHeight() - $(".header").innerHeight() - 100) {
            if (!$header.hasClass("header_fixed")) {
                $header.hide().fadeIn().addClass("header_fixed");
            }
        } else {
            $header.removeClass("header_fixed");
        }
    }

    onScroll();

    $(document).on("scroll", onScroll);
});

function initMap() {
    var center = {lat: 49.285828, lng: 23.506108};

    if($(document).innerWidth() > 1024) {
        center.lng += 0.01;
    }

    var map = new google.maps.Map(document.getElementById("contacts__map"), {
        zoom: 15,
        center: center,
        gestureHandling: 'auto',
        fullscreenControl: true,
        disableDoubleClickZoom: false,
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        draggable : true,
        clickableIcons: true,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    var positon = {lat: 49.285828, lng: 23.506108};

    new google.maps.Marker({
        position: positon,
        map: map,
    });
}
